<template>
    <div>
        <div class="header">
            <Row>
                <Col span="12">
                    <h1 class="title">{{ $t('navigation.templateSpaces') }}
                        ({{ total }})
                    </h1>
                </Col>
                <Col span="12" class="clearfix">
                    <Button class="float-right" type="primary" @click="searchNew">{{ $t('pages.template.search') }}</Button>
                    <Input v-model="query" class="float-right margin-right" clearable :placeholder="$t('pages.template.search')" style="width: auto" @keyup.native.enter="searchNew" @on-clear="searchNew" >
                        <Icon class="pointer" type="ios-search" slot="suffix" @click="searchNew" />
                    </Input>
                </Col>
            </Row>
        </div>
        <div class="main-content">
            <div class="search-bar">
                <div class="category-bar">
                    <span class="text" :style="{ width: width + 'px' }">{{ $t('pages.template.product') }}</span>
                    <RadioGroup class="radio-group-button" v-model="category" type="button" @on-change="searchNew">
                        <Radio label="all">{{ $t('common.all') }}</Radio>
                        <Radio v-for="item in templates_category" :label="item.key" :key="item.key">{{ item.text[lang]?item.text[lang]:item.text._ }}</Radio>
                    </RadioGroup>
                </div>
                <div class="dir-bar">
                    <span class="text" :style="{ width: width + 'px' }">{{ $t('pages.template.dir') }}</span>
                    <RadioGroup class="radio-group-button" v-model="dir" type="button" @on-change="searchNew">
                        <Radio label="all">{{ $t('common.all') }}</Radio>
                        <Radio v-for="item in dirList" :label="item.key" :key="item.key">{{ item.text[lang]?item.text[lang]:item.text._ }}</Radio>
                    </RadioGroup>
                </div>
                <div class="type-bar">
                    <span class="text" :style="{ width: width + 'px' }">{{ $t('pages.template.type') }}</span>
                    <RadioGroup class="radio-group-button" v-model="type" type="button" @on-change="searchNew">
                        <Radio label="all">{{ $t('common.all') }}</Radio>
                        <Radio v-for="item in typeList" :label="item.key" :key="item.key">{{ item.text[lang]?item.text[lang]:item.text._ }}</Radio>
                    </RadioGroup>
                </div>
                <div class="label-bar" v-for="item in expandList" :key="item.rowid">
                    <span class="text" :style="{ width: width + 'px' }">{{ item.name }}</span>
                    <RadioGroup class="radio-group-button" value="all" type="button" @on-change="handleLabel($event, item)">
                        <Radio label="all">{{ $t('common.all') }}</Radio>
                        <Radio v-for="subitem in item.children" :label="subitem.rowid" :key="subitem.rowid">{{ subitem.name }}</Radio>
                    </RadioGroup>
                </div>
                <div
                    class="drop-bar"
                    v-if="dropList.length">
                    <span v-for="item in dropList" :key="item.rowid">
                        <Dropdown class="dropdown" placement="bottom-start">
                            <Button type="text">
                                {{ drop[item.rowid].name }}
                                <Icon type="ios-arrow-down"></Icon>
                            </Button>
                            <DropdownMenu slot="list">
                                <DropdownItem @click.native="handleLabel('all', item)">{{ $t('common.all') }}</DropdownItem>
                                <DropdownItem
                                    v-for="subitem in item.children"
                                    :key="subitem.rowid"
                                    @click.native="handleLabel(subitem.rowid, item, subitem)">{{ subitem.name }}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </span>
                </div>
            </div>
            <no-data v-show="list.length === 0" :text="$t('pages.template.noTmpl')"></no-data>
            <div v-show="list.length > 0" class="items-wrap">
                <div class="item" v-for="(item, index) in list" :key="item.rowid">
                    <div class="img-wrap">
                        <img class="image-center template-preview-image-bkg" :src="`/cym/${item.cover_material_uri}/thumbnail?size=[-1,286]`">
                        <div class="img-mask" @click="handlePreview(index)">
                        </div>
                        <div class="marks">
                            <span
                                v-for="mark in item.mark_list"
                                :key="mark.key"
                                class="mark-item">{{ mark.text[lang]?mark.text[lang]:mark.text._ }}</span>
                        </div>
                        <div class="edit pointer" @click="handleAddTpml(item.rowid)">{{ $t('pages.template.useTmpl') }}</div>
                    </div>
                    <div class="name-wrap">
                        <div class="name line-overflow">
                            {{ item.name }}
                        </div>
                    </div>
                </div>
                <Spin fix size="large" v-if="loading"></Spin>
                <Page
                    class="page"
                    :total="total"
                    :current="current"
                    :page-size="pageSize"
                    show-elevator
                    @on-change="nextPage" />
            </div>
        </div>
        <add-modal
            :show="addModalShow"
            :department_id="department_id"
            :title="$t('pages.screen.addScreenTip')"
            @cancel="handleAddCancel"
        ></add-modal>
    </div>  
</template>
<script>
    import noData from '@/views/components/noData'
    import addModal from '@/views/screens/mediaScreens/components/add'
    import { PreviewImage } from 'components/preview.js'

    export default {
        name: "",
        mixins: [],
        components: {
            noData,
            addModal
        },
        props: [],
        data () {
            return {
                total: 0,
                query: '',
                current: 1,
                pageSize: 36,
                list: [],
                loading: false,
                typeList: [],
                type: 'all',
                dirList: [],
                dir: 'all',
                label_id_list: [],
                expandList: [],
                dropList: [],
                drop: {},
                addModalShow: false,
                category: 'all'
            }
        },
        computed: {
            department_id() {
                return this.$store.state.user_tree.department_id
            },
            templates_category() {
                return this.$store.state.templates_category
            },
            is_org() {
                return this.$store.state.default_bucket_tokens.org
            },
            width() {
                return this.$store.state.lang !== 'zh-Hans'?'76':'46'
            },
            lang() {
                return this.$store.state.lang
            }
        },
        methods: {
            searchNew() {
                if (this.loading == true) return
                this.current = 1
                this.getData()
            },
            handleAddTpml(rowid) {
                if (this.$store.state.rolesList.length === 0) return this.addModalShow = true
                const { href } = this.$router.resolve({
                    name: "templateDesign",
                    query: {
                        state: 'add',
                        rowid: rowid,
                        templateType: 'template',
                        spaceType: this.is_org?'company':'person'
                    }
                });
                window.open(href, '_blank')
            },
            handleAddCancel() {
                this.addModalShow = false
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                    query: this.query,
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize,
                    admin_tag_id_list: JSON.stringify(this.label_id_list),
                    data_type: 'static'
                }
                if (this.category !== 'all') {
                    params.category_list = JSON.stringify([this.category])
                }
                if (this.type !== 'all') {
                    params.type_list = JSON.stringify([this.type])
                }
                if (this.dir !== 'all') {
                    params.dir_list = JSON.stringify([this.dir])
                }
                return this.$api.get('templates/search', { params }).then(({ data, total_count, page }) => {
                    if (page === this.current) {
                        this.list = data
                        this.total = total_count
                    }
                })
            },
            getTypeList() {
                this.$api.get('templates/type_list').then(({ data }) => {
                    this.typeList = data
                })
            },
            getDirList() {
                this.$api.get('templates/dir_list').then(({ data }) => {
                    this.dirList = data
                })
            },
            getLabels() {
                return this.$api.get('templates/admin_tags').then(({ data }) => {
                    let list = data.filter(el => el.children && el.children.length)
                    let dropList = [],
                        expandList = [];
                    list.map((item) => {
                        if (item.unfold_style === 'drop') {
                            dropList.push(item)
                            this.drop[item.rowid] = {
                                name: item.name
                            }
                        } else {
                            expandList.push(item)
                        }
                    })
                    this.dropList = dropList
                    this.expandList = expandList
                    //处理label被删除情形
                    this.label_id_list.map(id => {
                        let result = null
                        list.map((item) => {
                            result = item.children.find(child => child.rowid === id)
                        })
                        //在列表中未找到，则删除
                        if (!result) {
                            let index = this.label_id_list.indexOf(id)
                            this.label_id_list.splice(index, 1)
                        }
                    })
                }).catch(() => {})
            },
            handleLabel(e, item, subitem=null) {
                if (item.unfold_style === 'drop') {
                    let name = ''
                    if (e === 'all') {
                        name = item.name
                    } else {
                        name = subitem.name
                    }
                    this.drop[item.rowid] = {
                        name: name
                    }
                }
                item.children.map(el => {
                    let index = this.label_id_list.indexOf(el.rowid)
                    if (index > -1) {
                        this.label_id_list.splice(index, 1)
                    }
                })
                if (e !== 'all') {
                    this.label_id_list.push(e)
                }
                this.getData()
            },
            handlePreview(index) {
                let resources = this.list.map((item) => {
                    let params = {
                            src: `/cym/${item.cover_material_uri}/preview`,
                            name: item.name,
                            format_name: item.template.material_type,
                            type: 'image',
                            type_text: this.$t('pages.template.template'),
                            rowid: item.rowid,
                            resolution: `${item.template.width}X${item.template.height}PX`,
                            material_type: 'template',
                            allow: true
                        },
                        size = item.bytes_size/(1024*1024);
                    if (size >= 1) {
                        params.bytes_size = Math.round(size*10)/10 + 'M'
                    } else if (size >= 0 && size < 1) {
                        params.bytes_size = Math.round(10*item.bytes_size/1024)/10 + 'KB'
                    }
                    return params
                })
                PreviewImage({
                    sources: resources,
                    index: index,
                    from: 'template',
                    department_id: this.department_id,
                    handleUseTmpl: this.handleAddTpml
                })
            }
        },
        mounted() {
            this.getLabels()
            this.getData()
            this.getTypeList()
            this.getDirList()
        }
    }
</script>
<style scoped lang="less">
.main-content {
    .search-bar {
        font-size: 14px;
        .type-bar, .dir-bar, .category-bar {
            .text {
                position: relative;
                display: inline-block;
                padding-right: 16px;
                width: 46px;
                height: 32px;
                line-height: 32px;
                color: #b4b9bf;
                vertical-align: middle;
                &::after {
                    content: '\200B';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 15px;
                    background: #b4b9bf;
                }
            }
        }
        .label-bar {
            .text {
                display: inline-block;
                overflow: hidden;
                position: relative;
                padding-right: 16px;
                width: 46px;
                height: 32px;
                line-height: 32px;
                color: #b4b9bf;
                vertical-align: top;
                text-overflow:ellipsis;
                white-space: nowrap;
                &::after {
                    content: '\200B';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 15px;
                    background: #b4b9bf;
                }
            }
            .radio-group-button {
                width: calc(100% - 46px);
                vertical-align: top;
            }
        }
        .drop-bar {
            margin-top: 10px;
            .dropdown {
                margin: 0 10px 10px -15px;
            }
        }
    }
    .items-wrap {
        width: calc(100% + 20px);
        .item {
            display: inline-block;
            position: relative;
            margin: 0 20px 20px 0;
            width: calc(16.6% - 20px);
            .img-wrap {
                position: relative;
                padding-top: 116.8%;
                width: 100%;
                background: #cfcfcf;
                box-shadow: 0px 3px 6px 0px rgba(207,207,207,0.50);
                &:hover {
                    .checkbox, .play, .more, .edit {
                        display: block;
                    }
                    .img-mask {
                        opacity: 1;
                    }
                    .marks {
                        display: none;
                    }
                }
                .marks {
                    position: absolute;
                    left: 10%;
                    bottom: 20px;
                    font-size: 12px;
                    color: #fff;
                    .mark-item {
                        margin-right: 5px;
                        padding: 0 5px;
                        background: rgba(0,0,0,0.4);
                        border-radius: 4px;
                        line-height: 16px;
                    }
                }
                .img-mask {
                    cursor: zoom-in;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,.08);
                    transition: opacity .3s ease;
                }
                .edit {
                    display: none;
                    position: absolute;
                    left: 50%;
                    bottom: 20px;
                    transform: translateX(-50%);
                    width: 80%;
                    height: 35px;
                    background: rgba(0,0,0,0.4);
                    border-radius: 2px;
                    font-size: 14px;
                    line-height: 35px;
                    color: #fff;
                    text-align: center;
                    &:hover {
                        background: rgba(0,0,0,0.6);
                    }
                }
                @media screen and (min-width: 1600px) {
                    .edit {
                        height: 40px;
                        font-size: 16px;
                        line-height: 40px;
                    }
                }
            }
            .name-wrap {
                margin-top: 5px;
                .name {
                    display: inline-block;
                    position: relative;
                    max-width: calc(100% - 24px);
                    line-height: 21px;
                    vertical-align: middle;
                }
            }
        }
    }
}
</style>